// api.js 封装了所有的请求接口，而 request.js 封装了 axios 的请求配置和拦截器。这样做的好处是，当需要修改请求配置或者拦截器时，只需要修改 request.js 即可，而不需要修改每个请求接口文件。

// 配置接口后在业务层只需要用this.$api.接口名()调用接口即可

import * as product from "./product";
import * as user from "./user";
import * as order from "./order";

export default {
    product,
    user,
    order
};
