<template>
  <div class="login-container">
    <div class="login-dialog">
      <h1 style="text-align: center">单单有礼</h1>
      <div class="login-body">
        <t-form ref="form" :model="formData" :rules="rules" label-width="0px" label-position="top">
          <t-form-item label="用户名" prop="username">
            <t-input v-model="formData.username"></t-input>
          </t-form-item>
          <t-form-item label="密码" prop="password">
            <t-input type="password" v-model="formData.password"></t-input>
          </t-form-item>
          <t-form-item>
            <t-button type="primary" @click="login">登录</t-button>
          </t-form-item>
        </t-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        username: '',
        password: ''
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  methods: {
    async login() {
      const valid = await this.$refs.form.validate()
      if (valid !== true) {
        return
      }
      const userInfo = {
        username: this.formData.username,
        password: this.formData.password
      }
      // dispatch
      this.$api.user.login(userInfo).then((res) => {
        if (!res?.length) {
          this.$message.error('用户名或密码错误')
          return;
        }
        this.$message.success('登录成功')
        // random token
        const token = Math.random().toString(36).substr(2)
        localStorage.setItem('ddgt-token', token)
        localStorage.setItem(`${token}-expired`, Date.now() + 1000 * 60 * 60 * 24)
        // refresh window
        window.location.reload()
      }).catch(e => {
        this.$message.error('登录失败')
      })
    }
  }
}
</script>

<style lang="less">
.login-container {
  width: 100%;
  height: 100vh;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('../../public/fuji.jpg');
  background-size: cover;
  background-position: center;
}

.login-dialog {
  height: 480px;
  width: 360px;
  background: #ffffff;
  border-radius: 10%;
  opacity: 0.7;
  padding: 24px;

  .login-body {
    margin: 48px 8px;
  }
}
</style>
