// product 的请求接口

import request from "../request.js";

export function login(data) {
  return request({
    url: "/app/adminLogin",
    method: "post",
    data,
  });
}
