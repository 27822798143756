<template>
    <div class="home-container">
        <t-menu theme="light" defaultValue="goods" @change="changeHandler" style="margin-right: 40px">
            <template #logo>
                <!-- <img height="28" src="https://tdesign.gtimg.com/site/baseLogo-light.png" alt="logo" /> -->
                <h1>单单有礼</h1>
            </template>
            <!-- <t-menu-item value="item1" href="/vue" target="_blank">仪表盘</t-menu-item> -->
            <t-menu-item value="goods">商品列表</t-menu-item>
            <t-menu-item value="categoryList">分类列表</t-menu-item>
            <t-menu-item value="swiper">程序首图</t-menu-item>
            <t-menu-item value="orderCenter">订单中心</t-menu-item>
        </t-menu>
        <ResourceTable v-if="current === 'goods'" />
        <SwiperList v-if="current === 'swiper'" />
        <OrderCenter v-if="current === 'orderCenter'" />
        <CategoryList v-if="current === 'categoryList' " />
    </div>
</template>

<script>
import ResourceTable from './resource.vue';
import SwiperList from './swiperList.vue';
import OrderCenter from '../orderCenter';
import CategoryList from '../components/categoryList.vue';

export default {
    components: {
        ResourceTable,
        SwiperList,
        OrderCenter,
        CategoryList
    },
    data() {
        return {
            current: 'goods'
        }
    },
    methods: {
        changeHandler(value) {
            console.log('VALUE', value)
            this.current = value
        }
    }
}
</script>

<style lang="less" scoped>
.home-container {
    height: calc(100vh - 24px);
    display: flex;

    /deep/ .t-default-menu__inner {
        border-right: solid 1px #e8e8e8;
        min-width: 200px;
    }
}
</style>