<template>
    <div class="order-container">
        <h2>订单中心</h2>
        <div class="search">
            <t-input placeholder="请输入订单编号" style="width: 200px;" />
            <t-button theme="primary">搜索</t-button>
            <t-select defaultValue="5" placeholder="筛选订单状态" style="width: 200px">
                <t-option :key="5" value="5" label="全部"></t-option>
                <t-option :key="0" value="0" label="待付款"></t-option>
                <t-option :key="1" value="1" label="待发货"></t-option>
                <t-option :key="2" value="2" label="待收货"></t-option>
                <t-option :key="3" value="3" label="已完成"></t-option>
                <t-option :key="4" value="4" label="已取消"></t-option>
            </t-select>
        </div>
        <div class="order-list" v-for="item in data" :key="item.id">
            <t-list :split="true">
                <template #header>
                    <div class="order-time">
                        <span>下单时间：</span>
                        <span>{{ item.orderTime }}</span>
                    </div>
                    <div class="order-status">
                        <span>订单状态：</span>
                        <span>{{ orderStatusMap[item.data[0].orderStatus] }}</span>
                    </div>
                    <div class="order-user">
                        <span>下单用户：</span>
                        <t-image :src="item.data[0].memberInfo.avatarUrl" :style="{ width: '24px', height: '24px' }"
                            shape="circle" fit="cover" />
                        {{ item.data[0].memberInfo.nickName }}
                    </div>
                </template>
                <t-list-item v-for="good in item.data">
                    <t-list-item-meta :image="good.productInfo.imageUrl" :description="good.productInfo.description">
                        <div slot="title">
                            <span>{{ `${good.productInfo.name} x ${good.number}` }}</span>
                            <span>
                                （商品价格：
                                <span style="color: orange">{{ good.productInfo.price }}</span>
                                ）
                            </span>
                        </div>
                    </t-list-item-meta>
                </t-list-item>

                <template #footer>
                    <div class="footer">
                        <div class="footer-top">
                            总金额：￥
                            <span style="color: red; fontWeight: bold">{{ item.data.reduce((acc, cur) => acc +
                    cur.productInfo.price * cur.number, 0) }}</span>
                        </div>
                        <div class="footer-bottom">
                            <t-button theme="primary" style="background: #333"
                                @click="handleDelivery(item)" v-if="item.data[0].orderStatus == 1">点击发货</t-button>
                            <t-button theme="primary">查看物流</t-button>
                        </div>
                    </div>
                </template>
            </t-list>
        </div>
        <DeliveryDialog ref="deliveryDialogRef" @refresh="handleRefresh" />
        <!-- <t-table :data="data" :columns="columns" rowKey="id" :loading="isLoading" :pagination="pagination"
            @page-change="onPageChange" bordered>
            <template #orderId="{ row }">
                <div>{{ row.id }}</div>
            </template>
            <template #operation="{ row }">
                <div style="display: flex; gap: 4px">
                    <t-link theme="primary">编辑</t-link>
                </div>
            </template>
        </t-table> -->
    </div>
</template>

<script>
import DeliveryDialog from './components/deliveryDialog'
export default {
    components: {
        DeliveryDialog
    },
    data() {
        return {
            isLoading: false,
            data: [],
            columns: [
                {
                    colKey: 'orderId',
                    title: '订单编号',
                    dataIndex: 'orderId',
                    width: 200,
                    fixed: 'left',
                },
                {
                    colKey: 'orderStatus',
                    title: '订单状态',
                    dataIndex: 'orderStatus',
                    width: 100,
                },
                {
                    colKey: 'number',
                    title: '商品数量',
                    dataIndex: 'number',
                    width: 100,
                },
                {
                    colKey: 'orderTime',
                    title: '下单时间',
                    dataIndex: 'orderTime',
                    width: 200,
                },
                {
                    colKey: 'nickName',
                    title: '用户昵称',
                    dataIndex: 'nickName',
                    width: 140,
                },
                {
                    colKey: 'productInfo',
                    title: '商品详情',
                    dataIndex: 'productInfo',
                },
                {
                    colKey: 'operation',
                    title: '操作',
                    width: 100,
                    fixed: 'right',
                },
            ],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                showJumper: true,
                onChange: (pageInfo) => {
                    console.log('pagination.onChange', pageInfo);
                },
            },
            pageNo: 1,
            pageSize: 10,
            current: 1,
        }
    },
    computed: {
        orderStatusMap() {
            return {
                0: '待付款',
                1: '待发货',
                2: '待收货',
                3: '已完成',
                4: '已取消',
            }
        }
    },
    methods: {
        init() {
            this.$api.order.getOrderList().then((res) => {
                const orderKeyList = Object.keys(res);
                this.data = orderKeyList.map((key) => {
                    return {
                        id: key,
                        // 下单时间
                        orderTime: res[key][0].orderTime,
                        data: res[key],
                        outTradeNo: res[key][0].outTradeNo,
                        orderFee: res[key].reduce((acc, cur) => acc +
                            cur.productInfo.price * cur.number, 0)
                    }
                });
                // 按this.data里的orderTime排序
                this.data.sort((a, b) => {
                    return new Date(b.orderTime) - new Date(a.orderTime);
                });
            });
        },
        handleRefresh() {
            this.init();
        },
        onPageChange(pageInfo) {
            console.log('onPageChange', pageInfo);
            // this.current = pageInfo.current;
            // this.pageSize = pageInfo.pageSize;
            // this.init();
        },
        handleDelivery(data) {
            this.$refs.deliveryDialogRef?.handleOpen(data);
        }
    },
    created() {
        this.init();
    }
}

</script>

<style lang="less" scoped>
.order-container {
    width: 100%;
    height: calc(100vh - 36px);
    overflow: auto;

    .search {
        display: flex;
        gap: 8px;
        margin: 10px 0;
    }

    .order-list {
        border: 1px solid #ccc;
        margin: 10px 0;
        border-radius: 12px;

        /deep/ .t-list {
            border-radius: 12px;
        }

        /deep/ .t-list__header {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        .order-time {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }

        .order-status {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }

        .order-user {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }

        .footer {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .footer-bottom {
                border-top: 1px solid #ccc;
                padding-top: 12px;
                display: flex;
                gap: 8px;
            }

        }
    }
}
</style>