// product 的请求接口

import request from "../request.js";

export function getProductList(params) {
  return request({
    url: "/app/getAllGoodsList",
    method: "get",
    params,
  });
}

// 分页获取商品列表
export function getGoodsListByPage(params) {
  return request({
    url: "/app/getGoodsListByPage",
    method: "get",
    params,
  });
}

// 上传商品图片
export function uploadGoodsImage(data) {
  return request({
    url: "/upload",
    method: "post",
    data,
  });
}

// 添加商品
export function addGoods(data) {
  return request({
    url: "/app/addGoods",
    method: "post",
    data,
  });
}

// 删除商品
export function deleteGoods(data) {
  return request({
    url: "/app/deleteGoods",
    method: "post",
    data,
  });
}

// 修改商品
export function updateGoods(data) {
  return request({
    url: "/app/editGoods",
    method: "post",
    data,
  });
}

// 获取轮播图
export function getSwiperList() {
  return request({
    url: "/app/getSwiperList",
    method: "get",
  });
}

// 添加轮播图
export function addSwiper(data) {
  return request({
    url: "/app/addSwiper",
    method: "post",
    data,
  });
}

// 修改轮播图
export function patchUpdateSwiper(data) {
  return request({
    url: "/app/patchUpdateSwiper",
    method: "post",
    data,
  });
}

// 删除轮播图
export function deleteSwiper(data) {
  return request({
    url: "/app/deleteSwiper",
    method: "post",
    data,
  });
}

// 获取分类列表
export function getCategoryList() {
  return request({
    url: "/app/getCategory1List",
    method: "get",
  });
}

// addCategory1
export function addCategory1(data) {
  return request({
    url: "/app/addCategory1",
    method: "post",
    data,
  });
}

// deleteCategory1
export function deleteCategory1(data) {
  return request({
    url: "/app/deleteCategory1",
    method: "post",
    data,
  });
}

// patchUpdateCategory1
export function patchUpdateCategory1(data) {
  return request({
    url: "/app/patchUpdateCategory1",
    method: "post",
    data,
  });
}