<template>
    <t-dialog :closeOnOverlayClick="false" :visible="visible" width="calc(50vw)" header="添加新的轮播图"
        @confirm="handleConfirm" @close="handleClose">
        <div slot="body">
            <t-form :data="formData" ref="formRef" :rules="rules">
                <t-form-item label="轮播图名称" name="name">
                    <t-input v-model="formData.name" />
                </t-form-item>
                <t-form-item label="轮播图顺序" name="sort">
                    <t-input v-model="formData.sort" />
                </t-form-item>
                <t-form-item label="图片" name="url">
                    <t-upload ref="uploadRef" :onSuccess="onSuccess" v-model="files" :autoUpload="true" theme="image"
                        tips="请选择单张图片文件上传（上传成功状态演示）" accept="image/*" :action="action">
                    </t-upload>
                </t-form-item>
            </t-form>
        </div>
    </t-dialog>
</template>

<script>

export default {
    data() {
        return {
            // 新增或编辑
            status: 'new', // new, edit
            files: [],
            visible: false,
            formData: {},
            // 除了类别、图片和描述，其他都是必填
            rules: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                // sort只能输入数字
                sort: [
                    { required: true, message: '请输入顺序', trigger: 'blur' },
                    { pattern: /^[0-9]*$/, message: '请输入数字', trigger: 'blur' }
                ],
            }
        }
    },
    computed: {
        action() {
            return `https://www.dandangift.cn/app/uploadFile`
            // return `http://localhost:10088/app/uploadFile`
        }
    },
    methods: {
        onSuccess(file) {
            console.log('onSuccess', file)
            return true
        },
        handleOpen(data) {
            if (!data) {
                this.status = 'new';
                this.visible = true;
                return;
            }
            // 有data的情况下，可以做一些初始化操作
            const filterEmptyValueData = Object.keys(data).reduce((acc, key) => {
                if (data[key] !== undefined && data[key] !== null && data[key] !== 'null') {
                    acc[key] = data[key]
                }
                return acc
            }, {})

            this.status = 'edit';
            this.files = [{
                // name为data.url取最后一个/后的字符串
                name: data.url?.split('/').pop(),
                url: data.url
            }]
            this.formData = filterEmptyValueData || {}
            this.visible = true
        },
        handleClose() {
            this.visible = false
            // 清空表单
            this.formData = {};
            this.files = [];
        },
        async handleConfirm() {
            const valid = await this.$refs.formRef.validate();
            if (valid !== true) {
                return;
            }
            // 提交表单
            const data = {
                ...this.formData,
                url: this.files[0]?.response?.data || this.formData.url
            }
            if (this.status === 'new') {
                this.$api.product.addSwiper(data).then(res => {
                    this.$message.success('添加成功');
                    // 刷新列表
                    this.$emit('refresh')
                    this.handleClose();
                }).catch(e => {
                    this.$message.error('添加失败');
                })
                return;
            }
            // 编辑
            this.$api.product.patchUpdateSwiper({
                swiperList: [data]
            }).then(() => {
                this.$message.success('调整成功');
                // 刷新列表
                this.$emit('refresh')
                this.handleClose();
            }).catch(e => {
                this.$message.error('调整失败', e);
            })

        }
    },
}

</script>