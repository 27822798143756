import Vue from 'vue'
import TDesign from 'tdesign-vue';
import 'tdesign-vue/es/style/index.css';
import request from './service'

Vue.prototype.$api = request

import App from './App.vue'

Vue.config.productionTip = false
Vue.use(TDesign)

new Vue({
  render: h => h(App),
}).$mount('#app')
