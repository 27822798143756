<template>
    <div class="category-container">
        <h2>一级分类数据</h2>
        <div class="action">
            <t-button @click="handleOpen">新建</t-button>
        </div>
        <div class="swiper-table">
            <t-table row-key="index" :columns="columns" :data="categoryList" :loading="loading" lazyLoad
                dragSort="row-handler" @drag-sort="onDragSort">
                <template #operation="{ row }">
                    <!-- <t-link theme="primary" @click="handleEdit(row)">编辑</t-link> -->
                    <t-popconfirm content="确认删除该分类吗？" @confirm="() => handleDelete(row)">
                        <t-link theme="danger" style="margin-left: 8px">删除</t-link>
                    </t-popconfirm>
                </template>
            </t-table>
        </div>
    </div>
</template>

<script>
import {
    MoveIcon,
} from 'tdesign-icons-vue';
export default {
    data() {
        return {
            loading: false,
            categoryList: [
            ],
            columns: [
                {
                    colKey: 'drag', // 列拖拽排序必要参数
                    title: '排序',
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    cell: (h) => (
                        <span>
                            <MoveIcon />
                        </span>
                    ),
                    width: 46,
                },
                {
                    colKey: 'label',
                    title: '分类名称',
                    dataIndex: 'label',
                    width: 200,
                },
                {
                    colKey: 'operation',
                    title: '操作',
                    dataIndex: 'operation',
                    width: 200,
                    scopedSlots: { customRender: 'operation' }
                }
            ],
        }
    },
    methods: {
        init() {
            this.loading = true;
            this.categoryList = [];
            this.$api.product.getCategoryList().then((res) => {
                this.categoryList = res?.map((item) => {
                    return {
                        ...item,
                        id: item.uuid
                    }
                })
                this.loading = false;
            }).catch(e => {
                this.loading = false;
                this.$message.error('获取分类列表失败', e);
            })
        },
        handleOpen() {
            let name = '';
            const onChange = (e) => {
                name = e;
            }
            const mydialog = this.$dialog({
                header: '新建分类',
                body: <t-input onChange={(e) => { onChange(e) }} placeholder="请输入分类名称"></t-input>,
                className: 't-dialog-new-class1 t-dialog-new-class2',
                style: 'color: rgba(0, 0, 0, 0.6)',
                onConfirm: ({ e }) => {
                    this.$api.product.addCategory1({
                        name
                    }).then(() => {
                        this.$message.success('新建成功');
                        setTimeout(() => {
                            this.init();
                        }, 1000);
                        mydialog.hide();
                    }).catch(e => {
                        this.$message.error('新建失败', e);
                    })
                },
                onCancel: () => {
                    mydialog.hide();
                }
            });
        },
        handleEdit(data) {
        },
        handleDelete(data) {
            this.$api.product.deleteCategory1({
                id: data.id
            }).then(() => {
                this.$message.success('删除成功');
                setTimeout(() => {
                    this.init();
                }, 1000);
            }).catch(e => {
                this.$message.error('删除失败', e);
            })
        },
        onDragSort(params) {
            this.loading = true;
            // 深拷贝一份数据
            const tempList = JSON.parse(JSON.stringify(this.categoryList));
            // 需要移动的源数据
            const current = params.current;
            // 需要移动到的目标数据
            const target = params.target;
            // 交换位置
            const currentIndex = tempList.findIndex((item) => item.id === current.id);
            const targetIndex = tempList.findIndex((item) => item.id === target.id);
            const temp = tempList[currentIndex];
            tempList[currentIndex] = tempList[targetIndex];
            tempList[targetIndex] = temp;
            // 调整后将数据提交到后端
            this.$api.product.patchUpdateCategory1({
                category1List: tempList?.map((item, index) => {
                    return {
                        ...item,
                        uuid: item.id.toString(),
                        sort: index
                    }
                })
            }).then(() => {
                this.$message.success('调整成功');
                setTimeout(() => {
                    this.init();
                }, 1000);
            }).catch(e => {
                this.loading = false;
                this.$message.error('调整失败', e);
            })
        },
    },
    created() {
        this.init();
    }
}
</script>

<style lang="less" scoped>
.swiper-container {
    width: 100%;

    /deep/ .t-swiper {
        width: 100%;
    }

    /deep/ .t-swiper__wrap {
        width: 800px;
        margin: auto;
    }

    .action {
        display: flex;
        gap: 8px;
        margin-top: 12px;
        margin-bottom: 12px;
        border-top: solid 1px #f0f2f5;
        padding-top: 12px;
    }
}
</style>