<template>
    <t-dialog :closeOnOverlayClick="false" :visible="visible" width="calc(50vw)" header="添加新的商品"
        @confirm="handleConfirm" @close="handleClose">
        <div slot="body">
            <t-form :data="formData" ref="formRef" :rules="rules">
                <t-form-item label="商品名称" name="name">
                    <t-input v-model="formData.name" />
                </t-form-item>
                <t-form-item label="价格" name="price">
                    <t-input v-model="formData.price" />
                </t-form-item>
                <t-form-item label="类别1" name="category1">
                    <t-input v-model="formData.category1" />
                </t-form-item>
                <t-form-item label="类别2" name="category2">
                    <t-input v-model="formData.category2" />
                </t-form-item>
                <t-form-item label="商品描述" name="description">
                    <t-textarea v-model="formData.description" placeholder="请输入商品描述" />
                </t-form-item>
                <t-form-item label="商品参数" name="info">
                    <t-textarea v-model="formData.info" placeholder="请输入商品参数，如大小规格等" />
                </t-form-item>
                <t-form-item label="商品首图" name="imageUrl">
                    <t-upload ref="uploadRef" :onSuccess="onSuccess" v-model="files" :autoUpload="true" theme="image"
                        tips="请选择单张图片文件上传（上传成功状态演示）" accept="image/*" :action="action">
                    </t-upload>
                </t-form-item>
                <t-form-item label="图片滚动列表" name="swiperUrl">
                    <t-upload ref="uploadRef2" :action="action" v-model="files2" :auto-upload="true" multiple
                        theme="image-flow" :abridge-name="[6, 6]" tips="允许选择多张图片文件上传，最多只能上传 8 张图片" accept="image/*"
                        :max="8"></t-upload>
                </t-form-item>
                <t-form-item label="首页展示" name="showInHome">
                    <t-switch v-model="formData.showInHome" />
                </t-form-item>
            </t-form>
        </div>
    </t-dialog>
</template>

<script>

export default {
    data() {
        return {
            // 新增或编辑
            status: 'new', // new, edit
            files: [],
            // 商品轮播图
            files2: [],
            visible: false,
            formData: {},
            // 除了类别、图片和描述，其他都是必填
            rules: {
                name: [
                    { required: true, message: '请输入商品名称', trigger: 'blur' }
                ],
                price: [
                    { required: true, message: '请输入价格', trigger: 'blur' }
                ],
                category1: [
                    { required: true, message: '请输入类别1', trigger: 'blur' }
                ],
                category2: [
                    { required: true, message: '请输入类别2', trigger: 'blur' }
                ],
            }
        }
    },
    computed: {
        action() {
            return `https://www.dandangift.cn/app/uploadFile`
            // return `http://localhost:10088/app/uploadFile`
        },
    },
    methods: {
        onSuccess(file) {
            console.log('onSuccess', file)
            return true
        },
        handleOpen(data) {
            if (!data) {
                this.status = 'new';
                this.visible = true;
                return;
            }
            // 有data的情况下，可以做一些初始化操作
            const filterEmptyValueData = Object.keys(data).reduce((acc, key) => {
                if (data[key] !== undefined && data[key] !== null && data[key] !== 'null') {
                    acc[key] = data[key]
                }
                return acc
            }, {})
            // filterEmptyValueData中value为'true'或'false'的情况下，转为boolean
            filterEmptyValueData.showInHome = filterEmptyValueData.showInHome === 'true'

            this.status = 'edit';
            this.files = [{
                // name为data.imageUrl取最后一个/后的字符串
                name: data.imageUrl?.split('/').pop(),
                url: data.imageUrl
            }]
            this.files2 = data.moreImage ? data.moreImage?.split(',').map(url => ({
                name: url?.split('/').pop(),
                url
            })) : []
            console.log('filterEmptyValueData', filterEmptyValueData)
            this.formData = filterEmptyValueData || {}
            this.visible = true
        },
        handleClose() {
            this.visible = false
            // 清空表单
            this.files = [];
            this.files2 = [];
            this.formData = {};
        },
        async handleConfirm() {
            const valid = await this.$refs.formRef.validate();
            if (valid !== true) {
                return;
            }
            const moreImage = this.files2?.map((item) => {
                if (item.url) {
                    return item.url;
                }
                return item.response.data;
            })

            // 提交表单
            const data = {
                ...this.formData,
                buyNumber: 0,
                showInHome: this.formData.showInHome ? 'true' : 'false',
                imageUrl: this.files[0]?.response?.data || this.formData.imageUrl,
                moreImage: moreImage.join(',')
            }

            if (this.status === 'new') {
                this.$api.product.addGoods(data).then(res => {
                    this.$message.success('添加成功');
                    // 刷新列表
                    this.$emit('refresh')
                    this.handleClose();
                }).catch(e => {
                    this.$message.error('添加失败');
                })
                return;
            }
            // 编辑
            this.$api.product.updateGoods(data).then(res => {
                this.$message.success('编辑成功');
                // 刷新列表
                this.$emit('refresh')
                this.handleClose();
            }).catch(e => {
                this.$message.error('编辑失败');
            })
        }
    },
}

</script>