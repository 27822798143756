var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resource-container"},[_c('h2',[_vm._v("商品列表")]),_c('div',{staticClass:"resource-top"},[_c('div',{staticClass:"operation"},[_c('t-button',{attrs:{"theme":"primary"},on:{"click":_vm.addProduct}},[_vm._v("新增")])],1),_c('div',{staticClass:"table-search"},[_c('t-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入商品名称"}}),_c('t-button',{attrs:{"theme":"primary"}},[_vm._v("搜索")])],1)]),_c('t-table',{attrs:{"data":_vm.data,"columns":_vm.columns,"rowKey":"id","loading":_vm.isLoading,"pagination":_vm.pagination,"selected-row-keys":_vm.selectedRowKeys,"bordered":""},on:{"change":_vm.rehandleChange,"page-change":_vm.onPageChange,"select-change":_vm.onSelectChange},scopedSlots:_vm._u([{key:"imageUrl",fn:function(ref){
var row = ref.row;
return [(row.imageUrl && row.imageUrl !== 'null')?_c('div',{staticClass:"image-container",on:{"click":function($event){return _vm.onShowImage(row.imageUrl)}}},[_c('img',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":row.imageUrl,"alt":"商品图片"}})]):_c('div',[_vm._v("--")])]}},{key:"info",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.info !== 'null' ? row.info : '--')+" ")]}},{key:"description",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.description !== 'null' ? row.description : '--')+" ")]}},{key:"showInHome",fn:function(ref){
var row = ref.row;
return [_c('t-switch',{attrs:{"defaultValue":row.showInHome === 'true'},on:{"change":function (e) { _vm.handleChangeShow(e, row) }}})]}},{key:"operation",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"display":"flex","gap":"4px"}},[_c('t-link',{attrs:{"theme":"primary"},on:{"click":function($event){return _vm.handleEdit(row)}}},[_vm._v("编辑")]),_c('t-popconfirm',{attrs:{"content":"确认删除该商品吗？"},on:{"confirm":function () { return _vm.handleDeleteProduct(row); }}},[_c('t-link',{attrs:{"theme":"danger"}},[_vm._v("删除")])],1)],1)]}}])}),_c('AddProduct',{ref:"addProductRef",on:{"refresh":_vm.onRefresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }