// request.js
import axios from "axios";

const request = axios.create({
  // baseURL: "http://localhost:10088",
  baseURL: "https://www.dandangift.cn",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

request.interceptors.request.use(
  (config) => {
    // do something before request is sent
    if(config.useWx){
      config.baseURL = "https://api.weixin.qq.com";
    }

    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    // 取出返回的data
    const res = response.data;
    // 如果返回的状态码不是200，说明接口请求失败

    if (res.code && res.code !== 200) {
      // 弹出错误信息
      console.error(res.msg || "Error");
      return Promise.reject(new Error(res.msg || "Error"));
    } else {
      // 返回data数据
      return res.data || res;
    }
  },
  (error) => {
    // do something with response error
    return Promise.reject(error);
  }
);

export default request;
