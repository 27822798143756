<template>
  <Home v-if="token" />
  <Login v-else />
</template>

<script>
import Home from './home'
import Login from './components/login.vue'

export default {
  name: 'App',
  components: {
    Home,
    Login
  },
  computed: {
    token() {
      const localToken = localStorage.getItem('ddgt-token');
      // 检测token是否过期，超过24小时则过期
      if (localToken) {
        const expired = localStorage.getItem(`${localToken}-expired`)
        if (expired && Date.now() < expired) {
          return localToken
        }
      }
      return '';
    }
  },
}
</script>
