// product 的请求接口

import request from "../request.js";

// 获取订单列表
export function getOrderList(params) {
  return request({
    url: "/app/getAllOrderList",
    method: "get",
    params,
  });
}

// 发货
export function deliverGoods(data) {
  return request({
    url: "/app/deliverGoods",
    method: "post",
    data,
  });
}

// 获取物流公司列表
export function getExpressList() {
  return request({
    url: "/app/getExpressList",
    method: "get",
  });
}

// 获取订单详情
export function queryOrder(params) {
  return request({
    url: "/app/wechat/query",
    method: "get",
    params,
    responseFromWx: true,
  });
}