<template>
    <div class="delivery">
        <t-dialog :visible.sync="visible" header="订单发货" width="1200" @confirm="handleSubmit" @close="handleClose">
            <div class="info-card">
                <div class="info-card-item">
                    交易单号：{{ orderData.transactionId }}
                </div>
                <div class="info-card-item">
                    支付金额：￥{{ orderData.orderFee }}
                </div>
                <div class="info-card-item">
                    支付时间：{{ orderData.payTime }}
                </div>
            </div>
            <div class="delivery-form">
                <t-form ref="form" :model="formData" label-width="100px">
                    <t-form-item label="商品信息" name="detail">
                        <t-textarea v-model="formData.detail" placeholder="商品信息会向用户展示，请保持与订单信息一致，多个商品信息用：隔开。"
                            :maxcharacter="120" />
                    </t-form-item>
                    <t-form-item label="发货方式" name="deliveryWay">
                        <t-radio-group v-model="formData.deliveryWay">
                            <t-radio :value="1">物流快递</t-radio>
                            <t-radio :value="2">同城配送</t-radio>
                            <t-radio :value="3">用户自提</t-radio>
                            <t-radio :value="4">虚拟发货</t-radio>
                        </t-radio-group>
                    </t-form-item>
                    <t-form-item label="快递公司" v-if="formData.deliveryWay < 3">
                        <t-select :scroll="{ type: 'virtual' }" filterable v-model="formData.deliveryCompany"
                            placeholder="请选择快递公司">
                            <t-option v-for="item in deliveryList" :key="item.delivery_id" :label="item.delivery_name"
                                :value="item.delivery_id" />
                        </t-select>
                    </t-form-item>
                    <t-form-item label="快递单号" v-if="formData.deliveryWay < 3">
                        <t-input v-model="formData.deliveryNo" placeholder="请填写快递单号"></t-input>
                    </t-form-item>
                </t-form>
            </div>
        </t-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            visible: false,
            orderData: {},
            formData: {
                deliveryWay: 1
            },
            deliveryList: []
        }
    },
    methods: {
        // 提交发货
        async handleSubmit() {
            const valid = await this.$refs.form.validate()
            if (valid === true) {
                console.log('formData', this.formData)
                const transData = {
                    orderId: this.orderData.id,
                    order_key: {
                        transaction_id: this.orderData.transactionId,
                        order_number_type: 2,
                    },
                    payer: {
                        openid: this.orderData.openid,
                    },
                    logistics_type: this.formData.deliveryWay,
                    // upload_time,时间格式如：2024-08-24T13:29:35.120+08:00
                    upload_time: new Date().toISOString(),
                    // 物流快递时是2，其他情况是1
                    delivery_mode: this.formData.deliveryWay === 1 ? 2 : 1,
                    is_all_delivered: true,
                    shipping_list: this.orderData.data?.map((item) => {
                        return {
                            item_desc: item.productInfo.name,
                            express_company: this.formData.deliveryCompany,
                            tracking_no: this.formData.deliveryNo,
                            contact: {
                                // 需要补充用户地址相关
                                consignor_contact: '123',
                            }
                        }
                    })
                }
                this.$api.order.deliverGoods(transData).then((res) => {
                    console.log('res', res)
                    if (res.errmsg === 'ok') {
                        this.$message.success('发货成功')
                        this.handleClose();
                        this.$emit('refresh');
                    } else {
                        this.$message.error('发货失败', res.errmsg)
                    }
                }).catch(e => {
                    this.$message.error('发货失败', e)
                })
            }
        },

        // 关闭弹窗
        handleClose() {
            this.visible = false
            this.formData = {
                deliveryWay: 1
            }
        },

        // 获取快递公司列表
        getDeliveryList() {
            this.$api.order.getExpressList().then((res) => {
                console.log('handleDelivery', res);
                if (res.errcode === 0) {
                    this.deliveryList = res?.delivery_list || [];
                }
            }).catch(e => {
                this.$message.error('获取快递公司列表失败', e)
            })
        },

        handleOpen(data) {
            this.getDeliveryList();
            console.log('data', data)
            this.loading = true
            // 获取订单详情
            this.$api.order.queryOrder({
                outTradeNo: data.outTradeNo,
                mchid: 1679217568
            }).then((res) => {
                this.orderData = {
                    ...data,
                    transactionId: res.transactionId,
                    payTime: res.successTime,
                    openid: res.payer.openid
                }
                this.loading = false
                this.visible = true
            }).catch(e => {
                this.loading = false
                this.$message.error('获取订单详情失败', e)
            })

        }
    }
}
</script>

<style lang="less" scoped>
.delivery {
    .info-card {
        margin-left: 20px;
        margin-bottom: 20px;

        &-item {
            display: flex;
            flex-direction: column;
        }
    }

    /deep/ .t-dialog__body {
        padding: 24px;
    }
}
</style>