<template>
    <div class="resource-container">
        <h2>商品列表</h2>
        <div class="resource-top">
            <div class="operation">
                <t-button theme="primary" @click="addProduct">新增</t-button>
            </div>
            <div class="table-search">
                <t-input placeholder="请输入商品名称" style="width: 200px;" />
                <t-button theme="primary">搜索</t-button>
            </div>
        </div>
        <t-table :data="data" :columns="columns" rowKey="id" :loading="isLoading" :pagination="pagination"
            :selected-row-keys="selectedRowKeys" @change="rehandleChange" @page-change="onPageChange"
            @select-change="onSelectChange" bordered>
            <template #imageUrl="{ row }">
                <div class="image-container" @click="onShowImage(row.imageUrl)" v-if="row.imageUrl && row.imageUrl !== 'null'">
                    <img :src="row.imageUrl" alt="商品图片" style="width: 100px; height: 100px;" />
                </div>
                <div v-else>--</div>
            </template>
            <template #info="{ row }">
                {{ row.info !== 'null' ? row.info : '--' }}
            </template>
            <template #description="{ row }">
                {{ row.description !== 'null' ? row.description : '--' }}
            </template>
            <template #showInHome="{ row }">
                <t-switch :defaultValue="row.showInHome === 'true'" @change="(e) => { handleChangeShow(e, row) }" />
            </template>
            <template #operation="{ row }">
                <div style="display: flex; gap: 4px">
                    <t-link theme="primary" @click="handleEdit(row)">编辑</t-link>
                    <t-popconfirm content="确认删除该商品吗？" @confirm="() => handleDeleteProduct(row)">
                        <t-link theme="danger">删除</t-link>
                    </t-popconfirm>
                </div>
            </template>
        </t-table>
        <AddProduct ref="addProductRef" @refresh="onRefresh" />
    </div>
</template>

<script>
import AddProduct from './components/addProduct'

export default {
    components: {
        AddProduct
    },
    data() {
        return {
            data: [],
            isLoading: false,
            selectedRowKeys: [],
            columns: [
                {
                    colKey: 'row-select',
                    type: 'multiple',
                    width: 46,
                },
                {
                    colKey: 'name',
                    title: '商品名称',
                    dataIndex: 'name',
                    width: 200,
                    fixed: 'left',
                },
                {
                    colKey: 'price',
                    title: '价格',
                    dataIndex: 'price',
                    width: 100,
                },
                {
                    colKey: 'category1',
                    title: '类别1',
                    dataIndex: 'category1',
                    width: 100,
                },
                {
                    colKey: 'category2',
                    title: '类别2',
                    dataIndex: 'category2',
                    width: 100,
                },
                {
                    colKey: 'imageUrl',
                    title: '商品图片',
                    dataIndex: 'imageUrl',
                    width: 140,
                },
                {
                    colKey: 'description',
                    title: '商品描述',
                    dataIndex: 'description',
                },
                {
                    colKey: 'info',
                    title: '商品参数',
                    dataIndex: 'info',
                    width: 200,
                },
                {
                    colKey: 'showInHome',
                    title: '首页展示',
                    dataIndex: 'showInHome',
                    width: 100,
                },
                {
                    colKey: 'operation',
                    title: '操作',
                    width: 100,
                    fixed: 'right',
                },
            ],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                showJumper: true,
                onChange: (pageInfo) => {
                    console.log('pagination.onChange', pageInfo);
                },
            },
        };
    },
    async mounted() {
        await this.fetchData(this.pagination.current, this.pagination.pageSize);
    },
    methods: {
        async fetchData(current, pageSize) {
            this.isLoading = true;
            this.$api.product.getGoodsListByPage({ pageNum: current, pageSize }).then(res => {
                this.data = res?.list || [];
                this.pagination.total = res.total;
                this.isLoading = false;
            }).catch(e => {
                this.$message.error('获取商品列表失败');
                this.isLoading = false;
            })
        },

        onRefresh() {
            this.fetchData(this.pagination.current, this.pagination.pageSize);
        },

        addProduct() {
            this.$refs.addProductRef?.handleOpen()
        },

        handleDeleteProduct(row) {
            this.$api.product.deleteGoods({
                productIdList: [row.id]
            }).then(res => {
                this.$message.success('删除成功');
                this.onRefresh();
            }).catch(e => {
                this.$message.error('删除失败');
            })
        },

        onShowImage(url) {
            window.open(url);
        },

        // BaseTable 中只有 page-change 事件，没有 change 事件
        rehandleChange(changeParams, triggerAndData) {
            console.log('分页、排序、过滤等发生变化时会触发 change 事件：', changeParams, triggerAndData);
        },

        // BaseTable 中只有 page-change 事件，没有 change 事件
        async onPageChange(pageInfo) {
            console.log('page-change', pageInfo);
            this.pagination.current = pageInfo.current;
            this.pagination.pageSize = pageInfo.pageSize;
            await this.fetchData(pageInfo.current, pageInfo.pageSize);
        },

        onSelectChange(value, { selectedRowData }) {
            this.selectedRowKeys = value;
            console.log(value, selectedRowData);
        },

        handleEdit(row) {
            this.$refs.addProductRef?.handleOpen(row);
        },

        handleChangeShow(value, data) {
            // 编辑
            this.$api.product.updateGoods({
                ...data,
                showInHome: value ? 'true' : 'false'
            }).catch(e => {
                this.$message.error('编辑失败');
            })
        }
    },
};
</script>

<style>
.resource-container {
    padding: 20px;

    .resource-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }


    .table-search {
        display: flex;
        align-items: center;
        gap: 12px;
    }
}
</style>