<template>
    <div class="swiper-container">
        <h2>程序首页轮播图</h2>
        <t-swiper :duration="300" :interval="2000">
            <t-swiper-item v-for="item in swiperList" :key="item.id">
                <img :src="item.url" alt="轮播图" style="width: 100%; height: 600px;" />
            </t-swiper-item>
        </t-swiper>
        <div class="action">
            <t-button @click="handleOpen">新建</t-button>
            <!-- <t-button>编辑</t-button> -->
        </div>
        <div class="swiper-table">
            <t-table row-key="index" :columns="columns" :data="swiperList" :loading="loading" lazyLoad
                dragSort="row-handler" @drag-sort="onDragSort">
                <template #operation="{ row }">
                    <t-link theme="primary" @click="handleEdit(row)">编辑</t-link>
                    <t-popconfirm content="确认删除该商品吗？" @confirm="() => handleDelete(row)">
                        <t-link theme="danger" style="margin-left: 8px">删除</t-link>
                    </t-popconfirm>
                </template>
            </t-table>
        </div>
        <AddSwiper ref="addSwiperRef" @refresh="init" />
    </div>
</template>

<script>
import AddSwiper from './components/addSwiper.vue';
import {
    MoveIcon,
} from 'tdesign-icons-vue';
export default {
    components: {
        AddSwiper,
    },
    data() {
        return {
            loading: false,
            swiperList: [
            ],
            columns: [
                {
                    colKey: 'drag', // 列拖拽排序必要参数
                    title: '排序',
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    cell: (h) => (
                        <span>
                            <MoveIcon />
                        </span>
                    ),
                    width: 46,
                },
                {
                    colKey: 'name',
                    title: '名称',
                    dataIndex: 'name',
                    width: 200,
                },
                {
                    colKey: 'url',
                    title: '图片',
                    dataIndex: 'url',
                    width: 200,
                    scopedSlots: { customRender: 'url' }
                },
                {
                    colKey: 'operation',
                    title: '操作',
                    dataIndex: 'operation',
                    width: 200,
                    scopedSlots: { customRender: 'operation' }
                }
            ],
        }
    },
    methods: {
        init() {
            this.$api.product.getSwiperList().then((res) => {
                // 返回数据按sort排序， sort是个字符串，需要转换为数字
                this.swiperList = res?.sort((a, b) => parseInt(a.sort) - parseInt(b.sort));
            }).catch(e => {
                this.$message.error('获取轮播图失败', e);
            })
        },
        getSwiperList() {
            this.$api.product
        },
        handleOpen() {
            // 如果数量已经超过8个，不允许再添加
            if (this.swiperList.length >= 8) {
                this.$message.error('轮播图数量已达上限');
                return;
            }

            this.$refs.addSwiperRef.handleOpen();
        },
        handleEdit(data) {
            this.$refs.addSwiperRef.handleOpen(data);
        },
        handleDelete(data) {
            this.$api.product.deleteSwiper({
                id: data.id
            }).then(() => {
                this.$message.success('删除成功');
                this.init();
            }).catch(e => {
                this.$message.error('删除失败', e);
            })
        },
        onDragSort(params) {
            // 需要移动的源数据
            const current = params.current;
            // 需要移动到的目标数据
            const target = params.target;
            // 交换位置
            const currentIndex = this.swiperList.findIndex((item) => item.id === current.id);
            const targetIndex = this.swiperList.findIndex((item) => item.id === target.id);
            const temp = this.swiperList[currentIndex];
            this.swiperList[currentIndex] = this.swiperList[targetIndex];
            this.swiperList[targetIndex] = temp;
            // 调整后将数据提交到后端
            this.$api.product.patchUpdateSwiper({
                swiperList: this.swiperList?.map((item, index) => {
                    return {
                        ...item,
                        id: item.id.toString(),
                        sort: index.toString()
                    }
                })
            }).then(() => {
                this.$message.success('调整成功');
                this.init();
            }).catch(e => {
                this.$message.error('调整失败', e);
            })
        },
    },
    created() {
        this.init();
    }
}
</script>

<style lang="less" scoped>
.swiper-container {
    width: 100%;

    /deep/ .t-swiper {
        width: 100%;
    }

    /deep/ .t-swiper__wrap {
        width: 800px;
        margin: auto;
    }

    .action {
        display: flex;
        gap: 8px;
        margin-top: 12px;
        margin-bottom: 12px;
        border-top: solid 1px #f0f2f5;
        padding-top: 12px;
    }
}
</style>